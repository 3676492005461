:root {
    --color-background: #e5e5e5;
    --color-white: #ffffff;
    --color-black: #000000;
    // Brand
    --color-midnight-500: #212243;
    --color-primary: var(--color-midnight-500);
    --color-brand: var(--color-midnight-500);
    // Grays
    --color-gray-1: var(--color-black);
    --color-gray-2: #343434;
    --color-gray-3: #656565;

    --color-light-1: #e3e3e3;
    --color-light-3: #f0f0f0;
    --color-light-5: #f5f5f5;
    --color-light-6: #fafafa;

    --color-gray-200: var(--color-light-5);
    --color-gray-300: var(--color-light-3);
    --color-gray-400: var(--color-light-1);
    --color-gray-500: var(--color-gray-3);
    --color-gray-600: var(--color-gray-2);
    // Blue: Light blue
    --color-blue-200: #ecf0f7;
    --color-blue-300: #ced9eb;
    --color-blue-400: #9eb3d8;
    --color-blue-500: #3d66b0;
    --color-blue-600: #385994;
    // Indigo/Blueberry: Blueberry
    --color-blueberry-200: #e8e9f1;
    --color-blueberry-300: #c6c7dd;
    --color-blueberry-400: #8d8fba;
    --color-blueberry-500: #00048e;
    --color-blueberry-600: #13166f;
    // Red: Tomato
    --color-red-200: #feeceb;
    --color-red-300: #fdd0ce;
    --color-red-400: #fca09c;
    --color-red-500: #f9423a;
    --color-red-600: #d44316;
    // Yellow: Dark Orange
    --color-yellow-200: #fffae6;
    --color-yellow-300: #fff2c0;
    --color-yellow-400: #ffe682;
    --color-yellow-500: #ffcd05;
    --color-yellow-600: #ecc00e;
    // Green: Mint
    --color-green-200: #edf7f5;
    --color-green-300: #d3eae7;
    --color-green-400: #a7d6cf;
    --color-green-500: #489d90;
    --color-green-600: #428077;
    // Statuses
    --color-status-success: #43d011;
    --color-status-error: #be2d2e;
    --color-status-warning: #fced17;
    --color-status-info: #13166f;
}
