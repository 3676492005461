@use 'spaces';
@use 'colors';
@use 'indices';
@use 'components';

@import 'utilities';

* {
    font-family: 'Montserrat', sans-serif;
}
html {
    scroll-behavior: smooth;
}
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
button[disabled] {
    pointer-events: none;
}

body * {
    box-sizing: border-box;
}
