.tooltip {
    padding: var(--spacing-3) var(--spacing-4);
    text-align: center;
    font-size: 12px !important;
    border-radius: 4px !important;
    box-shadow: 0px 8px 32px rgb(26 26 26 / 24%);

    :global .tippy-content {
        padding: var(--spacing-0);
    }

    &--light {
        background-color: var(--color-white) !important;
        color: var(--color-black) !important;

        :global .tippy-arrow::before {
            color: var(--color-white);
        }
    }

    &--dark {
        background-color: var(--color-brand) !important;
        color: var(--color-white) !important;

        :global .tippy-arrow::before {
            color: var(--color-brand);
        }
    }

    &--error {
        background-color: var(--color-red-500) !important;
        color: var(--color-white) !important;

        :global .tippy-arrow::before {
            color: var(--color-red-500);
        }
    }

    &--warning {
        background-color: var(--color-yellow-500) !important;
        color: var(--color-black) !important;

        :global .tippy-arrow::before {
            color: var(--color-yellow-500);
        }
    }

    &--info {
        background-color: var(--color-blue-500) !important;
        color: var(--color-white) !important;

        :global .tippy-arrow::before {
            color: var(--color-blue-500);
        }
    }

    &--success {
        background-color: var(--color-green-500) !important;
        color: var(--color-white) !important;

        :global .tippy-arrow::before {
            color: var(--color-green-500);
        }
    }
}
