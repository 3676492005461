:root {
    --z-deep: -999999;
    --z-default: 1;
    --z-masked: 100;
    --z-mask: 200;
    --z-sticky: 300;
    --z-header: 400;
    --z-sidebar: 450;
    --z-sidebar-secondary: 440;
    --z-toast: 500;
    --z-dropdown: 600;
    --z-overlay: 700;
    --z-spinner: 800;
    --z-modal: 900;
    --z-popup: 950;
}
