:root {
    /* same as theme/nesto */
    --space-0: 0px;
    --space-1: 4px;
    --space-2: 8px;
    --space-3: 16px;
    --space-4: 32px;
    --space-5: 64px;
    --space-6: 128px;
    --space-7: 256px;
    --space-8: 512px;
}
