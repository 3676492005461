.error-info {
    min-height: 100vh;
    padding: var(--spacing-6);

    .card {
        width: min(100%, 800px);
        display: flex;
        flex-direction: column;
        gap: var(--spacing-4);

        &__header {
            display: flex;
            align-items: center;
            gap: var(--spacing-3);
        }

        .support-portal-link {
            font-weight: var(--font-weight-6);
            color: var(--color-blue-600);
        }

        .text--semibold {
            font-weight: var(--font-weight-6);
        }
    }
}
