.buttonNav {
    box-sizing: border-box;
    border: none;
    border-radius: 0;

    min-width: 0;
    width: 100%;

    padding: 0;
    margin: 0;

    text-decoration: none;
    word-wrap: break-word;

    font-weight: 600;
    font-size: 10px;
    line-height: 1.7em;

    &.primary {
        color: var(--color-white);
        background-color: var(--color-midnight-500);

        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-block: var(--space-3);
        padding-inline: var(--space-3);

        gap: var(--space-1);

        @media screen and (min-width: 964px) {
            justify-content: flex-start;
        }

        &:hover,
        &:focus {
            background-color: var(--color-gray-600);
            background-color: #424368;
        }
        &.active {
            background-color: var(--color-white);
            color: var(--color-midnight-500);
        }

        &.disabled {
            text-decoration: none;

            background-color: var(--color-midnight-500);
            color: var(--color-gray-500);
            cursor: not-allowed;
        }
    }

    &.secondary {
        color: var(--color-blue-500);

        &:hover,
        &:focus {
            color: var(--color-midnight-500);
        }
        &.active {
            color: var(--color-midnight-500);
        }

        &.disabled {
            background-color: var(--color-midnight-500);
            color: var(--color-gray-500);
            pointer-events: none;
        }
    }

    &.justifyEnd {
        justify-content: flex-end;
    }

    & > *:nth-child(2) {
        display: none;
    }

    @media screen and (min-width: 964px) {
        & > *:nth-child(2) {
            display: block;
        }
    }
}
